import { Center, Circle, Flex, FlexProps, Image as ChakraImage, Spacer, VStack } from "@chakra-ui/react";
// import Image from "next/image";

const WorldPage = () => {
	return (
		<VStack
			minH="100vh"
			bg="#"
			backgroundColor="gray.50"
			// backgroundImage="linear-gradient(to right, #fc4a1a, #ff6d00);"
			// justify={"center"}
		>
			<Flex w="full" justify={"center"} mt={4}>
				<ChakraImage
					src="/logo.svg"
					width="72px"
					height="80px"
					alt="image of latinshop logo"
					// style={{ position: "absolute", top: "32px", left: "32px" }}
				/>
			</Flex>
			<Center pt={28}>
				<CountryCube
					href="https://latinshop.com.au"
					flagUrl="/images/flags/au-flag.png"
					caption="com"
					flexProps={{ sx: { "#__caption::after": { content: "'.au'" } }, bg: "#709ed5" }}
				/>
			</Center>
		</VStack>
	);
};

export default WorldPage;

const CountryCube = (props: { flagUrl: string; href: string; caption: string; flexProps?: FlexProps }) => {
	const { flagUrl, href, caption, flexProps } = props;
	return (
		<a href={href}>
			<Flex width="144px" height="144px" rounded="2xl" pos="relative" direction="column" {...flexProps}>
				<Circle size="64px">
					<ChakraImage
						src={flagUrl}
						alt="image"
						width={"64px"}
						height="64px"
						pos="absolute"
						top={2}
						left={2}
					/>
				</Circle>
				<Spacer />

				<Flex
					px={3}
					fontWeight="bold"
					fontSize="4xl"
					id="__caption"
					w="full"
					color={"gray.50"}
					// mt={-4}
					justify={"end"}
				>
					{caption}
				</Flex>
			</Flex>
		</a>
	);
};
